html {
  background: #fff;
}

body {
  margin: 0;
  height: inherit;
  overflow-x: hidden;
}

html,
body {
  width: 100%;
}

#root {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

::selection {
  color: white;
  background: #e40134;
}

